<template>
  <div>
    <b-row>
      <b-col class="mt-2"
        ><h3>{{ fragetext }}</h3></b-col
      >
    </b-row>
    <b-row v-if="hinweistext">
      <b-col class="mb-2">{{ hinweistext }}</b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(opt, index) in optionen"
        :key="`${key}RV${index}`"
        :xs="xs"
        :sm="sm"
        :md="md"
        :lg="lg"
        :xl="xl"
      >
        <b-form-checkbox
          v-model="opt.checked"
          v-bind:checked="opt.checked"
          button
          button-variant="outline-dark"
          :name="`Frage_${Frage}`"
          class="mx-1 my-1"
          @change="handleChange(index)"
          :key="key"
          >{{ opt.text }}</b-form-checkbox
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fragetext: "Neigt Deine Haut zu:",
      hinweistext: undefined,
      optionen: [
        { text: "Mitessern", antagonist: false, checked: false },
        { text: "Pickeln", antagonist: false, checked: false },
        { text: "Entzündungen", antagonist: false, checked: false },
        { text: "Grießkörnern / Milien", antagonist: false, checked: false },
        { text: "Nichts davon", antagonist: true, checked: false },
      ],
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
      xl: 4,
      key: Math.random()
    };
  },
  props: {
    Frage: { required: true },
  },
  methods: {
    handleChange(index) {
      if (this.optionen[index].antagonist) {
        this.optionen.forEach((v, i) => {
          if (i !== index) {
            v.checked = false;
          }
        });
      } else {
        this.optionen.forEach((v) => {
          if (v.antagonist && v.checked) {
            v.checked = false;
          }
        });
      }
      const selected = [];
      this.optionen.forEach((v) => {
        if (v.checked) {
          selected.push(v.value ? v.value : v.text);
        }
      });
      this.$store.commit("setAnwser", {
        Frage: this.Frage,
        Fragentext: this.fragetext,
        Antwort: selected,
      });
    },
  },
  beforeMount() {
    const answ = this.$store.getters.getAnwsers;
    if (answ[this.Frage - 1]) {
      this.optionen.forEach((v) => {
        if (answ[this.Frage - 1].Antwort.indexOf(v.text) >= 0) {
          v.checked = true;
        }
      });
    }
    this.key = Math.random()
  },
};
</script>